.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;

    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font {
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font .ql-picker-label[data-value="Nunito"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Nunito"]::before {
  font-family: "Roboto", cursive;
  ;
  content: "Nunito" !important;
}

/* Set content font-families */
.ql-font-Nunito {
  font-family: "Nunito", sans-serif;
  color: black;
}